import ApiService from './service';
import * as http from './http';

export class AssociateDetailsService extends ApiService {
    async addAssociateDetails(request, data) {
        const url = `${this.apiDomain}/${request.resource}`;
        return http.post(url, data, this.store);
    }

    async getDetailsByEmail(request, email) {
        const url = `${this.apiDomain}/${request.resource}/${email}`;
        const response = await http
            .get(url, this.store)
            .catch((err) => console.log(err));
        return { data: response.data };
    }

    async updateAssociateDetails(request, data) {
        const url = `${this.apiDomain}/${request.resource}`;
        return http.put(url, data, this.store);
    }

    async getPDFDetails(request) {
        try {
            const url = `${this.apiDomain}/${request.resource}`;
            const accept = 'application/zip, application/pdf'; // Accept both ZIP and PDF
    
            console.log('Request to backend:', {
                url: url,
                accept: accept,
                data: request.data
            });
    
            // Set responseType explicitly to arraybuffer
            const response = await http.downloadPostData(url, request.data, this.store, accept);
    
            console.log('Received file response:', response);
    
            return { data: response.data, headers: response.headers }; 
        } catch (error) {
            console.error('Error in getPDFDetails:', error.message);
            alert(`Error downloading file: ${error.message}`);
            throw error;
        }
    }
    
}
